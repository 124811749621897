@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker__input-container>input {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 7px 8px;
  width: 100%;
}

.awsui_button_vjswe_jz1q6_101.awsui_variant-normal_vjswe_jz1q6_126:not([aria-label="refresh"]):not(#\9) {
  background-color: #2C2F88!important;
  border-color: #2C2F88!important;
  color: white!important;
}

.awsui_button_vjswe_jz1q6_101.awsui_variant-normal_vjswe_jz1q6_126:not([aria-label="refresh"]):not(#\9):hover {
  background-color: #5351FB!important;
  border-color: #5351FB!important;
  color: white!important;
}

/* Estilos para elementos con aria-label="refresh" */
.awsui_button_vjswe_jz1q6_101.awsui_variant-normal_vjswe_jz1q6_126[aria-label="refresh"] {
  background-color: #ffffff !important;
  border-color: #2C2F88 !important;
}

.awsui_button_vjswe_jz1q6_101.awsui_variant-normal_vjswe_jz1q6_126[aria-label="refresh"] > span {
  color: #2C2F88 !important;
}

.awsui_button_vjswe_jz1q6_101.awsui_variant-normal_vjswe_jz1q6_126[aria-label="refresh"]:hover {
  background-color: #DFE8FF !important;
}

.react-datepicker__input-container>input:focus {
  outline: 1px solid var(--bs-blue);
}

#root>div:nth-child(1)>div>header>div>div.awsui_utilities_k5dlb_j1i9i_227>div.awsui_utility-wrapper_k5dlb_j1i9i_238.awsui_utility-type-menu-dropdown_k5dlb_j1i9i_263>div>div>div:nth-child(3)>div.awsui_dropdown_qwoo0_l5rqw_93.awsui-visual-refresh.awsui-context-top-navigation.awsui_open_qwoo0_l5rqw_236.awsui_with-limited-width_qwoo0_l5rqw_217.awsui_refresh_qwoo0_l5rqw_114.awsui_dropdown-drop-left_qwoo0_l5rqw_220 {
  border-radius: 20px;
  box-shadow: -1px 3px 18px 0px rgb(30 33 71) !important;
}

.awsui_dropdown-content-wrapper_qwoo0_l5rqw_93:not(#\9)::after {
  border: 1px solid #003aff5c !important;
}

.awsui_item-element_93a1u_1if6y_93.awsui_last_93a1u_1if6y_110:not(#\9) {
  border-bottom: transparent !important;
}

.awsui_header_sne0l_1jdy2_144:not(#\9) {
  border-bottom: 1px solid #003aff5c !important;
}

#awsui-button-dropdown__header3-1728945444025-8495 {
  border: 3px solid #0034e5db;
}

img.sentiment {
  display: inline;
  width: 2rem;
  margin-right: 1rem;
}

span.segment {
  color: #4C4F7D;
  cursor: pointer;
}

img.sentiment-icon {
  width: 40px;
}

tr.contact-table {
  cursor: pointer;
}

span.audio-start {
  color: #4C4F7D;
  cursor: pointer;
}

.awsui_click-target_gwq0h_10iv9_479:not(#\9):not(.awsui_wrapper-container_gwq0h_10iv9_286):not(.awsui_header-container-button_gwq0h_10iv9_364):hover {
  color: #2C2F88 !important;
}

:root {
  --color-text-expandable-section-hover-lomw6v: #2C2F88;
}

[class*='awsui_click-target']:hover {
  color: #2C2F88 !important;
}


.awsui_breadcrumb_1kosq_1md44_145>.awsui_anchor_1kosq_1md44_155:not(#\9),
.awsui_ghost-breadcrumb_1kosq_1md44_146>.awsui_anchor_1kosq_1md44_155:not(#\9) {
  color: #7C91FD;
}

.awsui_token-box_dm8gx_1e6km_154 {
  border-color: #002b80 !important;
}
.awsui_token-box_dm8gx_1e6km_154 svg path {
  stroke: #002b80 !important;
}

.awsui_button_vjswe_jz1q6_101.awsui_variant-primary_vjswe_jz1q6_210:not(#\9):hover{
  background-color: #5351FB!important;
  border-color: #5351FB!important;
  color: white!important;
  border-color: #5351FB!important;
}
.awsui_button_vjswe_jz1q6_101.awsui_variant-primary_vjswe_jz1q6_210:not(#\9){
background-color: #2C2F88!important;
border-color: #2C2F88!important;
color: white!important;
border-color: #2C2F88!important;
}
