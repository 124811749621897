.highlight {
  background-color: var(--highlight-colour);
}

.charts {
  flex: 2 0 300px;
}

.transcribe-col {
  flex: 1 1 550px;

}
.call-details-col {
  flex: 1 1 550px;
}
.playing {
  background-color: #ffff00;
  text-decoration: underline;
}
.awsui_child_18582_bdxnb_97 {
  font-family: Helvetica, sans-serif;
  color: #080709 !important;
  font-weight: 400px !important;
}
.awsui_box_18wu0_2xnuk_207.awsui_key-label-variant_18wu0_2xnuk_303:not(#\9) {
  color: #080709;
  font-family: Helvetica, sans-serif;
  font-weight: 700px !important;
}
.awsui_heading-text_2qdw9_yzcal_264.awsui_heading-text-variant-h2_2qdw9_yzcal_275 {
  color: #080709;
}

